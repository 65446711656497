body {
  margin: 0;
  font-family: lato;
  font-size: 16px;
  color: #161617;
}

body input {
  font-family: lato;
}

a {
  color: #0A1229;
  text-decoration: none;

  &:hover {
    color: #18188c;
  }
}

.new-line {
  display: block;
  padding-bottom: 10px;
}

nav {
  // border-bottom: 1px solid #ccc;
  padding: 12px 0;
  background-color: #EEF3FB;
  gap: 32px;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1164px;
    padding: 0 24px;
    margin: 0 auto;
    gap: 16px;

    .left {
      width: 32px;

      .logo {
        display: flex;
        align-items: center;
        font-weight: bold;
        gap: 16px;

        // img {
        //   height: 46px;
        // }

        a {
          color: #364B7E;
          // padding-left: 16px;
          font-size: 20px;
          text-decoration: none;
        }
      }
    }

    .right {
      display: flex;
      // justify-content: center;

      // align-items: center;
      justify-content: right;
      // width: 32px;
      gap: 66px;

      .header-links {
        display: flex;
        align-items: center;
        gap: 32px;
        flex-wrap: wrap;

        a {
          color: #0A1229;
          text-decoration: none;

          &:hover {
            color: #18188c;
          }

          &.active {
            color: #18188c;
          }
        }
      }

      .profile {
        display: flex;
        gap: 8px;
        cursor: pointer;
        align-items: center;

        .color {
          display: block;
          width: 56px;
          height: 56px;
          border-radius: 28px;
          padding: 0;
          // margin: 8px;
          // margin-bottom: 12px;
        }

        .profile-avatar {
          height: 56px;

          img {
            width: 56px;
            border-radius: 30px;
          }
        }
      }
    }
  }
}

// nav > div {
  // display: flex;
  // gap: 20px;
  // flex-grow: 1;
// }

// nav > div > a {
//   display: block;
//   text-decoration: none;
//   color: #161617;
//   padding: 10px;
//   cursor: pointer;

// }


h1 {
  // text-align: center;
  font-size: 32px;
  margin-bottom: 16px;
}

h3 {
  font-size: 20px;
  font-weight: normal;
}

.container {
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1164px;
  margin-top: 128px;
  margin-bottom: 128px;
}

.events {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .event {
    margin-bottom: 30px;
    //font-size: 18px;

    .date {
      font-weight: bold;
      padding-right: 10px;
    }
  }
}

.event {
  .event-documents {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    gap: 10px;
  }
}

.people {
  .people-person {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;

    .people-person-organizations {
      display: flex;
      font-size: 16px;
      font-weight: bold;
      gap: 10px;
    }
  }
}

.person {
  .person-organizations {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    gap: 10px;
  }
  .person-documents {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    gap: 10px;
  }
}

.organizations {
  p span {
    display: block;
  }

  .organizations-organization {
    // padding: 20px 0;
    border-bottom: 1px solid #ccc;

    .organizations-organization-people {
      display: flex;
      font-size: 16px;
      font-weight: bold;
      gap: 10px;
    }
  }
}

.organization {
  .organization__graphics {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .organization-people {
    display: flex;
    font-size: 16px;
    font-weight: bold;
  }
}

.graphic {
  flex-direction: column;
  display: flex;

  &.line-diagram {
    height: 300px;
    width: 100%;
  }

  &.active-pie-diagram {
    width: 50%;
    min-width: 500px;
  }

  .graphic__title {
    display: flex;
    justify-content: center;
    font-style: italic;
  }

  .graphic__chart {
    flex-grow: 1;
    display: flex;
    min-height: 330px;
    height: 360px;
  }
}


.spinner {
  height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.login {
  height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;

  .title {
    display: flex;
    gap: 10px;
    margin-bottom: 28px;

    h3 {
      margin: 0;
      font-size: 18px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.15);
    // border: 1px solid #ccc;
    // padding: 30px;

    gap: 24px;

    .form-control {
      display: flex;
      flex-direction: column;

      label {
        padding-bottom: 4px;
      }

      input {
        // outline: none;
        text-align: left;
        border: 1px solid #0A1229;                                                                        ;
        font-size: 16px;
        padding: 0 15px;
        width: 280px;
        height: 46px;
        margin-top: 8px;
        // background-color: #eeeefc;

        ::placeholder {
          color: #0A1229;
        }

        &:focus {
          border: 1px solid #2E5480;
          background-color: #EEF3FB;
          outline-color: #2E5480;
          outline-width: inherit;
        }
      }

      .login-button {
        cursor: pointer;

        // background-color: #fff;
        color: #FCFEFE;
        background-color: #49769F;
        border: none;

        width: 160px;
        text-align: center;

        &:hover {
          background-color: #2E5480;
        }

        &:focus {
          background-color: #2E5480;
        }
      }
    }


  }
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    color: #18188c;
  }
}

.dropdown-content {
  padding-top: 10px;
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  // min-width: 60px;
  // padding: 8px;
  // z-index: 1;
  // margin-top: 10px;

  .dropdown-content-links {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.15);

    a {
      text-decoration: none;
      color: #161617;
      // padding: 16px 16px 0px;
      padding: 8px 16px;

      &:hover {
        color: #18188c;
        background-color: #eeeefc;
      }

      // &:last-child {
      //   padding-bottom: 16px;
      // }
    }
  }
}

.dropdown:hover .dropdown-content {
  // display: flex;
  display: block;
}

.home {
  margin-bottom: 40px;

  .home-meeting {
    margin-bottom: 64px;
  }

  .container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 36px;

    a {
      display: flex;
      flex-grow: 1;
      flex-basis: 200px;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      height: 300px;
      font-size: 24px;
      color: #000;
      border: 1px solid #0A1229;
      // box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.15);

      &:hover {
        color: #18188c;
        background-color: #eeeefc;
      }
    }
  }

  .home-tasks {
    margin-top: 36px;
    display: grid;
    grid-gap: 0;
    // min-width: 0;
    overflow-x: auto;
    // position: sticky;

    .task-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 8px;
      border-bottom: 0.4px solid #0A122964;
      position: relative;

      a {
        color: #0A1229;
        text-decoration: none;

        &:hover {
          color: #18188c;
        }
      }

      .completed {
        position: absolute;
      }

      .incompleted {
        opacity: 0.1;
      }
    }

    .profile {
      display: flex;
      gap: 16px;
      cursor: pointer;
      justify-content: left;
      align-items: center;
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #fff;
      padding: 12px 8px;

      .color {
        display: block;
        width: 56px;
        height: 56px;
        border-radius: 28px;
        padding: 0;
        position: relative;
      }

      .profile-avatar {
        position: relative;
        height: 56px;

        img {
          width: 56px;
          border-radius: 30px;
        }
      }

      &.active {
        .color::after, .profile-avatar::after {
          content: '';
          height: 14px;
          width: 14px;
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: #34bf34;
          border-radius: 7px;
          // border: 1px solid #fff;
        }
      }
    }
  }
}


.documents {
  p span {
    display: block;
  }

  .documents-document {
    // text-align: center;

    padding: 20px 0;
    border-bottom: 1px solid #ccc;

    a {
      color: #0A1229;
      text-decoration: none;

      &:hover {
        color: #18188c;
      }
    }
  }
}

.document {
  margin-bottom: 50px;

  .document-people, .document-events {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    gap: 10px;
  }

  .document-content {
    // margin: 0 auto;
    // max-width: 600px;
    // height: 140vh;
  }

  .document-book {
    display: flex;
    justify-content: center;
    // width: calc(100% - 10%);
    // max-width: 1110px;
    // box-sizing: border-box;

    .page-controls {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .react-pdf__Document {
      box-shadow: 0 30px 40px 0;
      border-radius: 8px;
      // width: 500px;
      // max-width: 100%;
      // position: relative;
      background-color: white;
      // box-sizing: border-box;
    }
  }
}

.player-wrapper {
  position: relative;
  height: fit-content;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  padding-top: 75%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.tasks-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.tasks-task {
  a {
    text-decoration: none;
    color: #0A1229;

    display: inline-block;

    &.completed {
      // display: none;
      color: #ccc;
      text-decoration: line-through;

      &:hover {
        color: #aaa;
      }
    }

    &:hover {
      color: #18188c;
    }
  }
}

.task {
  margin-bottom: 50px;

  .task-title {
    display: flex;
    gap: 8px;

    .task-status {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .completed {
        position: absolute;
      }
    }
  }

  .task-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    gap: 30px;

    .right {
      flex-basis: 20px;
    }

    .task-content-description {
      span {
        display: block;
        margin: 10px 0;
      }
    }
  }

  .task-documents {
    max-width: 600px;
    margin: 0 auto;
  }

  .complete {
    color: #FCFEFE;
    padding: 16px 48px;
    background-color: #49769F;
    cursor: pointer;

    &:hover {
      background-color: #2E5480;
    }
  }
}

.meetings {
  margin-bottom: 50px;

  .meetings-meeting {
    margin-bottom: 48px;
  }
}

.meeting {
  .meeting-events, .meeting-documents {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    // font-weight: bold;
    gap: 10px;
    margin-bottom: 32px;
    text-decoration: underline;

    .date {
      font-weight: bold;
      padding-right: 10px;
    }
  }
}

.economicBranch {
  .economicBranch__branches {
    display: flex;
    min-height: 300px;

    div {
      flex-grow: 1;
    }

    .economicBranch__branch__color {
      height: 16px;
      width: 16px;
      display: inline-block;
      margin-right: 8px;

      a {
        font-size: 16px;
      }
    }
  }

  .economicBranch-people, .economicBranch-organizations {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }
}
